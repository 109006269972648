/* eslint-disable standard/no-callback-literal */
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'change' }
const emailValidator = {
  required: true, type: 'email', message: 'Please input correct email', trigger: 'blur'
}
const testSelect = function (rule, value, cb) {
  if (value) {
    cb()
  }
  cb(false)
}
const CheckBoxValidator = {
  validator: testSelect, required: true, message: 'Please read and tick ', trigger: 'change'
}
export default {
  entity_name: [
    baseRequirement
  ],
  entity_phone: [
    baseRequirement
  ],
  entity_register_num: [
    baseRequirement
  ],
  entity_tax_num: [
    baseRequirement
  ],
  entity_industry_field: [
    baseRequirement
  ],
  entity_job_desc: [
    baseRequirement
  ],
  entity_address: [
    baseRequirement
  ],
  entity_country: [
    baseRequirement
  ],
  entity_suburb: [
    baseRequirement
  ],
  entity_city: [
    baseRequirement
  ],
  entity_postcode: [
    baseRequirement
  ],
  entity_email: [
    emailValidator
  ],
  entity_addressProveType: [
    baseRequirement
  ],
  entity_address_file_list: [
    baseRequirement
  ],
  entity_status_radio: [
    baseRequirement
  ],
  Active_Non_Financial_Entity_check: [
    CheckBoxValidator
  ],
  Passive_Non_Financial_Entity_check: [
    CheckBoxValidator
  ],
  Financial_Institution_check: [
    CheckBoxValidator
  ],
  entity_is_nz_taxer: [
    baseRequirement
  ],
  entity_nz_tax_number: [
    baseRequirement
  ],

  entity_tax_panes: [
    testSelect
  ]
}
